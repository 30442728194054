table {
    border-collapse: collapse;
   
}

td, th {
    border: 1px solid #ced4daa8;
    text-align: left;
   
  }
  tr:nth-child(even) {
    background-color: #E8F8FC;
  }
  tr:nth-child(odd) {
    background-color: #FFFFFF;
  }