:root {
  --jd-color-background-default: #f8f9fa;
  --jd-color-border: hsl(210, 16%, 93%);
  --jd-color-panel: #e9ecef;
}

.jodit-toolbar__box:not(:empty) {
  /* background: #f8f9fa; */
  background-color: white !important;
  border-color: rgb(123, 123, 123);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
}
.jodit-container:not(.jodit_inline) {
  border: none;
  /* background: #f8f9fa; */
}
.jodit-toolbar-editor-collection_mode_horizontal:after {
  display: none;
}
.jodit-container {
}
