/** @format */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #f6f7f8 !important;
}

:root {
  --first: "#274C77";
  --second: "#576a77";
  --third: "#E1F3FA";
}

/* element styling */
/* link style  */
a {
  text-decoration: none !important;
  color: inherit;
}
a.active {
  background: #426690;
  color: #fff !important;
  width: 100%;
  height: 100%;
}
a.active:hover {
  color: #576a77;
}
a:hover {
  color: inherit;
}
.navbar-link:hover {
  color: #e3d0d0 !important;
}

/* utility classess */
.first {
  /* color: #208996 !important; */
  color: #274c77 !important;
}
.second {
  color: #274c77 !important;
}
.third {
  color: #e1f3fa !important;
}
.forth {
  color: #e9ecef !important;
}
.fifth {
  color: #f8f9fa !important;
}
.white {
  color: #ffffff !important;
}
.bg-first {
  background: #274c77 !important;
}
.bg-second {
  background: #576a77 !important;
}
.bg-third {
  /* background: #ced4da !important; */
  background: #e1f3fa !important;
}
.bg-forth {
  background: #e9ecef !important;
}
.bg-fifth {
  /* background: #f8f9fa !important; */
  background: #f2f4f7 !important;
}
.bg-header {
  background: #e4e7ec !important;
}
.bg-card {
  background: #e8f8fc !important;
}
/* side Navbar styling */
.NewNavbar {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
}

/* Custom link text color */
.custom-link-color {
  color: #1f73b7 !important;
}

/* navbar */
.navbar {
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  width: 100% !important;
  box-shadow: 0px 1px rgba(0, 0, 0, 0.25);
}
.footer {
  position: fixed;
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
}

.import-button {
  border: 1px solid #313131 !important;
  border-radius: 4px;
  color: #313131 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 60px;
  gap: 15px;
  width: 380px;
  height: 55px;
  font-size: 0.9rem;
}

.import-file-button {
  border: 1px dotted #919191 !important;
  border-radius: 4px;
  color: #919191 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 60px;
  gap: 15px;
  width: 380px;
  height: 55px;
  font-size: 0.9rem;
}

/* .csv-form-control {
  display: none !important;
} */

/* overides MUI classess */
.css-rnmm7m-MuiPaper-root-MuiDi-paper {
  border-radius: 0 !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  z-index: 5 !important;
}

/* table styling */
.MuiDataGrid-root .MuiDataGrid-viewport {
  background-color: #ededed;
}
.MuiDataGrid-root {
  border: none !important;
}

.css-7o3esj-MuiButtonBase-root-MuiButton-root {
  color: #000 !important;
  margin-right: 30px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  background: #c5c5c5 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.css-7o3esj-MuiButtonBase-root-MuiButton-root:first-child {
  margin-left: 10px;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
  background: none !important;
}
.react-tel-input .form-control:focus {
  border-color: #576a77 !important;
  box-shadow: 0 0 0 1px #576a77 !important;
}
.special-label {
  background: inherit !important;
  top: -20% !important;
}
.overflow-hidden {
  overflow: hidden !important;
}

.lead-header {
  width: calc(100%-50px) !important;
}

.react-tel-input .form-control {
  border: none !important;
}

/* Customize SideBar */
.cust-sidebar {
  height: calc(100vh - 50px - 60px);
  overflow-y: scroll;
}
.cust-active {
  padding: 5px 5px;
  background: rgba(196, 196, 196, 0.74);
  border-radius: 5px;
}
.active-primary {
  color: #000 !important;
  background: #e9ecef !important;
  border-left: 4px solid #0c8599 !important ;
}

.profle-sidebar {
  width: 280px;
}

.Border {
  border: 2px solid red !important;
}

.pointer-wrapper:not(:first-child) {
  display: inline-block;
  margin-left: -40px !important;
}
.pointer-wrapper:first-child {
  padding-left: 3px;
}
.pointer-wrapper:last-child {
  padding-right: 0px;
}
.pointer-wrapper {
  display: inline-block !important;
  position: relative;
  height: 45px;
  background: #b0d7f1;
  padding-right: 6px;
  clip-path: polygon(75% 0%, 82% 50%, 75% 100%, 0% 100%, 8% 50%, 0% 0%);
}

.pointer {
  padding-right: 40px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  width: 100%;
  position: relative;
  background: white;
  clip-path: polygon(75% 0%, 82% 50%, 75% 100%, 0% 100%, 8% 50%, 0% 0%);
}
.pointer-wrapper.active-pointer {
  background: white;
  border-top: 2px solid #b0d7f1;
  border-bottom: 2px solid #b0d7f1;
}
.pointer.active-pointer {
  background: #274c77 !important;
  color: white;
  font-weight: 900;
}

.scroll-example1 {
  border-radius: 5px;
  overflow-x: scroll;
}

.scroll-example1::-webkit-scrollbar {
  /* display: none; */
  max-height: 5px;
}

/* .scroll-example1 {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

/* input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
.full-width {
  width: "100%";
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(228, 231, 236);
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: rgb(39, 76, 119); */
  background: #c1c1c1;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: rgba(39, 76, 119, 0.7); */
  background: #d3d3d3;
}

.template-card:hover {
  background-color: #f4f5f7;
}

.setting-wrapper {
  width: calc(100vw - 200px);
  margin-left: 200px;
  padding: 8px;
  height: calc(100vh - 110px);
  overflow-y: scroll;
}

.deal-pipeline-button {
  min-width: 90px !important;
}

@media screen and (max-width: 900px) {
  .sidebar {
    position: relative !important;
    top: 0 !important;
    height: auto !important;
    border-bottom: 1px solid gainsboro;
  }
  .setting-wrapper {
    margin-left: 0px;
    width: auto;
  }
}

/*React Calendar*/
/* MyCalendar.css */

.calendar-container {
  margin: 40px;
  /* padding: 10px; */
  background-color: #f9f9f9; /* Light background for the calendar */
  border-radius: 8px; /* Rounded corners */
  height: 750px;
}

.custom-calendar {
  padding: 10px;
  /* border: 1px solid #ccc; Border around the calendar */
}
.rbc-toolbar {
  /* background-color: #3f51b5; Custom toolbar background color */
}
.rbc-calendar {
  font-family: "Arial", sans-serif; /* Custom font family */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.rbc-event {
  background-color: #ff4081; /* Event background color */
  color: white; /* Event text color */
  border-radius: 4px; /* Rounded corners for events */
}

.rbc-month-view {
  padding: 10px; /* Padding for month view */
}

/* Responsive styles */
@media (max-width: 900px) {
  .calendar-container {
    height: 500px;
    margin: 40px 20px; /* Adjust margins for mobile view */
  }
}

/* Override specific styles from react-big-calendar */
