.sidebar {
  height: calc(100vh - 110px);
  width: 200px;
  border-top-right-radius: 20px;
  overflow-y: scroll;
  position: absolute;
  top: 110px;
  left: 0px;
  /* z-index: 10; */
}

@media screen and (max-width: 900px) {
  .sidebar {
    width: 100vw;
    border-top-right-radius: 0px;
    height: calc(100vh - 59px);
  }
}

.active-sidenavbar {
  background-color: rgba(0, 0, 0, 0.13) !important;
  color: var(--primary) !important;
  transition: all 0.5ms ease-in-out;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 40px !important;
}

.css-1ma1114-MuiList-root {
  margin-left: -18px !important;
}
